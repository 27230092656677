export const ROUTES = {
  maintenance: '/maintenance',
  home: '/',
  checkup: '/check-up',
  followup: '/follow-up',
  faq: '/faq',
  team: '/team',
  companies: '/companies',
  subscription_booking: '/subscription/booking',
  subscription_myAccount: '/subscription/my-account',
  subscription_payment: '/subscription/payment',
  subscription_confirmation: '/subscription/confirmation',
  bookingCompanies_form: '/booking-company/contact',
  bookingCompanies_confirm: '/booking-company/confirm',
  contactUs: '/contact-us/form',
  contactUs_confirm: '/contact-us/confirm',
  brochure: '/contact-us/brochure',
  jobs: '/recruitment',
  cookiesNotice: '/cookies-notice',
  legalMentions: '/legal-mentions',
  privacyNotice: '/privacy-notice',
  manifesto: '/manifesto',
  research: '/research',
  researchProjects: '/research-projects',
  signup: '/account/signup',
  login: '/account/login',
  logout: '/account/logout',
  forgottenPassword: '/account/forgotten-password',
  resetPasswordEmailSent: '/account/reset-password-email-sent',
  noticeKit_cui: '/notice-kit/cui',
  noticeKit_cub: '/notice-kit/cub',
  medicalCommittee: '/medical-committee',
  offerGift: '/offer-gift',
  gift_redeem: '/gift/redeem',
  gift_beneficiary: '/gift/beneficiary',
  gift_payment: '/gift/payment',
  gift_confirmation: '/gift/confirmation',
  redeem_myAccount: '/redeem/my-account',
  redeem_consume: '/redeem/consume',
  redeem_booking: '/redeem/booking',
  redeem_confirmBooking: '/redeem/confirm-booking',
  redeem_confirmation: '/redeem/confirmation',
  landing_medecine: '/landing/medecine'
} as const
